var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legalContent" }, [
      _c("h3", [_vm._v("리뷰마인드 개인정보 수집 및 이용 – V1")]),
      _vm._v(
        ' 리뷰마인드는 개인정보를 안전하게 보호하기 위하여 "정보통신망 이용촉진 및 정보보호 등에 관한 법률"을 비롯한 모든 개인정보보호 규정, 가이드라인을 준수하고 있습니다. "개인정보처리방침"이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 리뷰마인드가 준수해야 할 지침을 의미합니다.'
      ),
      _c("br"),
      _vm._v(
        " 본 개인정보처리방침은 리뷰마인드가 제공하는 ‘리뷰마인드(ReviewMind)’ 서비스에 적용됩니다. "
      ),
      _c("b", [_vm._v("제1조 개인정보 수집")]),
      _vm._v(
        " 리뷰마인드에서 제공하는 서비스는 회원가입 및 서비스를 이용하는 과정에서 최소한의 개인정보를 수집하고 있습니다. "
      ),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("분류")]),
          _c("td", [_vm._v("수집 유형")]),
          _c("td", [_vm._v("수집 항목")]),
          _c("td", [_vm._v("이용목적")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("회원 가입 시")]),
          _c("td", [_vm._v("필수항목")]),
          _c("td", [_vm._v("이메일, 비밀번호, 이름, 핸드폰번호, 회사명")]),
          _c("td", [_vm._v("이용자 식별 및 중복 가입 방지, 고지사항 전달")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("1:1 문의 시")]),
          _c("td", [_vm._v("필수항목")]),
          _c("td", [_vm._v("이메일")]),
          _c("td", [_vm._v("고객 상담 접수 및 처리, 회신, 오류 확인")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("서비스 제공 시")]),
          _c("td", [_vm._v("필수항목")]),
          _c("td", [
            _vm._v(
              " 이메일, 이름, 회사명, 핸드폰번호, 카드번호(개인/법인카드), 카드 유효기간 "
            )
          ]),
          _c("td", [
            _vm._v(
              " 이용자에게 최적화된 서비스 제공, 신규 서비스 안내, 이용자 특성에 따른 서비스 안내, 접속 빈도 파악, 서비스 이용 통계 "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " 서비스 제공에 관한 계약의 이행 및 서비스 제공에 따른 요금 정산 시 "
            )
          ]),
          _c("td", [_vm._v("필수항목")]),
          _c("td", [
            _vm._v("이메일, 이름, 핸드폰번호, 회사명, 회사전화번호, 사업자번호")
          ]),
          _c("td", [
            _vm._v(
              "유료 서비스 이용, 환불 처리, 현금 영수증 발행 세금계산서 발행"
            )
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v("기타 서비스 이용")]),
          _c("td", [_vm._v("자동수집")]),
          _c("td", [_vm._v("서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보")]),
          _c("td", [_vm._v("본인 확인 및 서비스 이용 통계, 부정 이용 방지")])
        ])
      ]),
      _c("b", [_vm._v("제2조 개인정보 수집 방법")]),
      _vm._v(
        " 개인정보를 수집하는 경우에는 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 법령에 따라 동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로써 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우"
      ),
      _c("br"),
      _vm._v(" 2. 정보통신서비스의 제공에 따른 요금 정산을 위하여 필요한 경우"),
      _c("br"),
      _vm._v(
        " 3. 부가 서비스 이용 또는 이벤트 응모 과정에서 해당 이용자에게 개인정보 추가 수집에 대해 동의를 받는 경우"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " 서비스 이용 과정에서 이용자로부터 아래의 방법을 통해 개인정보가 수집될 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 1. PC웹, 모바일 웹/앱 이용 과정에서 단말기 정보(OS, 화면 사이즈, 디바이스 아이디, 휴대폰 기종, 단말기 모델명), IP 주소, 쿠키, 방문 일시, 부정 이용 기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집될 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 서비스 이용에 따른 본인 확인 및 개인 식별을 위해 웹페이지 등을 통해 이용자의 개인정보가 수집될 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 서비스 제공을 위해 필수적으로 요구되는 제휴, 연계 등을 위하여 제3자로부터 개인정보를 제공받고 있습니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제3조 개인정보 제공 및 위탁")]),
      _vm._v(
        " 리뷰마인드는 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에 제공하지 않습니다. 외부 업체에게 업무 위탁 시 계약서 등을 통해서 개인정보보호 관련 법규의 준수, 개인정보에 관한 비밀 유지, 제3자 제공에 대한 금지, 사고시의 책임 부담, 위탁기간, 처리 종료 후의 개인정보의 파기 의무 등을 규정하고, 이를 준수하도록 관리감독하고 있습니다. "
      ),
      _c("b", [_vm._v("제4조 웹사이트 정보 자동 수집 장치(쿠키)에 대한 사항")]),
      _vm._v(
        " 쿠키는 웹사이트를 운영하는 데 이용하는 서버가 이용자의 브라우저로 전송하는 작은 텍스트 파일로, 이용자의 웹사이트 설정 상태, 방문 기록, 이용 형태를 자동으로 저장하여 웹사이트 사용을 빠르고 편리하도록 도와주는 역할을 합니다. 쿠키는 이름이나 전화번호 등 개인을 식별하는 정보를 저장하지 않지만, 이용자 스스로 쿠키 자동 수집 여부를 선택할 수 있습니다. "
      ),
      _c("b", [_vm._v("쿠키 수집 거부 설정 방법")]),
      _vm._v(
        " 1. Internet Explorer : 웹 브라우저 상단의 [도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정]"
      ),
      _c("br"),
      _vm._v(
        " 2. Chrome : 웹 브라우저 우측의 [설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키]"
      ),
      _c("br"),
      _c("b", [_vm._v("제5조 개인정보의 처리 및 이용기간")]),
      _vm._v(
        " 리뷰마인드는 원칙적으로 개인정보의 수집 및 이용목적 달성 또는 보유기간이 만료되는 경우, 수집된 개인정보는 열람하거나 이용할 수 없도록 지체없이 파기 처리합니다."
      ),
      _c("br"),
      _vm._v(
        " 회원탈퇴 후 재가입을 반복하여 리뷰마인드가 제공하는 혜택 등으로 경제적인 이득을 취하거나, 명의를 무단으로 사용하는 편법 및 불법행위를 하는 행위를 막기 위해 회원탈퇴 후 6개월 동안 이름, 이메일, 연락처, 회사명, 사업자등록번호 정보를 보관하며, 접속IP 정보는 12개월 동안 보관합니다."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " 그 외 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 일정한 기간 동안 외부와 차단된 별도의 DB 또는 테이블에 분리 보관합니다. "
      ),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("보존 근거")]),
          _c("td", [_vm._v("보존 항목")]),
          _c("td", [_vm._v("보존 기간")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("전자상거래등에서의 소비자보호에 관한 법률")]),
          _c("td", [_vm._v("소비자 불만 또는 분쟁 처리에 관한 기록")]),
          _c("td", [_vm._v("3년")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("신용정보의 이용 및 보호에 관한 법률")]),
          _c("td", [_vm._v("신용정보의 수집/처리 및 이용 등에 관한 기록")]),
          _c("td", [_vm._v("3년")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("통신비밀보호법")]),
          _c("td", [_vm._v("서비스이용관련개인정보(로그인 기록)")]),
          _c("td", [_vm._v("3개월")])
        ])
      ]),
      _vm._v(
        " 개인정보 보호를 위해 1년 동안 서비스를 이용하지 않은 회원의 계정은 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및/또는 “개인정보보호법 제 39조의 6 제1항” 에 근거하여 휴면으로 전환되며, 전환된 회원의 개인정보는 별도 DB로 분리하여 안전하게 보호합니다. "
      ),
      _c("b", [_vm._v("제6조 개인정보의 파기방법")]),
      _vm._v(
        " 리뷰마인드는 이용자의 개인정보 유출로 인한 피해가 발생하지 않도록 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다. "
      ),
      _c("b", [_vm._v("제7조 이용자의 권리")]),
      _vm._v(
        " 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 아니하며, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다. 이용자가 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 동의하신 내용은 언제든지 철회하실 수 있으며, 회원탈퇴를 요청할 수도 있으며, 위 권리를 법정대리인을 통해서도 행사할 수 있습니다. 이 외에 추가로 확인하고 싶거나 정정, 해지하고 싶은 개인정보가 있을 경우, 고객서비스 담당부서로 연락 주시면 지체 없이 조치하겠습니다. "
      ),
      _c("b", [_vm._v("제8조 아동의 개인정보 보호")]),
      _vm._v(
        " 리뷰마인드는 아동의 개인정보를 보호하기 위하여, 만 14세 이상인 경우에만 회원가입을 허용하며 개인정보의 수집/이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. "
      ),
      _c("b", [_vm._v("제9조 개인정보의 안전성 확보 조치")]),
      _vm._v(
        " 리뷰마인드는 이용자들의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음의 사항을 포함하여 관계 법령상 요구되는 기술적/관리적/물리적 조치를 취하고 있습니다."
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 1. 기술적 조치"),
      _c("br"),
      _vm._v(
        " A. 리뷰마인드는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " B. 이용자들의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " C. 리뷰마인드는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " D. 리뷰마인드는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " E. 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다."
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 2. 관리적 조치"),
      _c("br"),
      _vm._v(
        " A. 리뷰마인드는 이용자들의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다."
      ),
      _c("br"),
      _c("p", [
        _vm._v(" i. 이용자들을 직접 상대로 하여 마케팅 업무를 수행하는 자"),
        _c("br"),
        _vm._v(" ii. 이용자들의 불만 및 이용문의 처리 업무를 수행하는 자"),
        _c("br"),
        _vm._v(
          " iii. 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자"
        ),
        _c("br"),
        _vm._v(" iv. 기타 업무상 개인정보의 취급이 불가피한 자"),
        _c("br")
      ]),
      _vm._v(
        " B. 리뷰마인드는 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " C. 리뷰마인드의 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다."
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 3. 물리적 조치"),
      _c("br"),
      _vm._v(
        " A. 리뷰마인드는 사무실 출입통제 시스템을 통하여 외부인의 출입을 차단하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " B. 리뷰마인드는 서버실 등 개인정보를 처리하는 장치 등이 위치한 장소에 대한 출입관리 시스템 등을 통하여 개인정보 취급자 이외의 자의 접근을 통제하고 있습니다. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " 단, 이용자 본인의 부주의나 인터넷 또는 통신상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 리뷰마인드는 일체의 책임을 지지 않습니다. "
      ),
      _c("b", [_vm._v("제10조 개인정보 보호책임자 및 담당자")]),
      _vm._v(
        " 리뷰마인드의 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로 연락해 주시기 바랍니다. 리뷰마인드는 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다. "
      ),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("구분")]),
          _c("td", [_vm._v("담당자(부서)")]),
          _c("td", [_vm._v("이메일")]),
          _c("td", [_vm._v("전화번호")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("고객서비스 담당부서")]),
          _c("td", [_vm._v("리뷰마인드 운영팀")]),
          _c("td", [_vm._v("info@reviewmind.com")]),
          _c("td", [_vm._v("0507-1352-5847")])
        ])
      ]),
      _c("br"),
      _vm._v(
        " 기타 개인정보침해에 대한 신고나 상담이 필요한 경우 아래 기관에 문의하시기 바랍니다. "
      ),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("구분")]),
          _c("td", [_vm._v("웹사이트")]),
          _c("td", [_vm._v("전화번호")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("개인정보침해 신고센터")]),
          _c("td", [_vm._v("http://privacy.kisa.or.kr")]),
          _c("td", [_vm._v("국번없이 118")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("대검찰청 사이버수사과")]),
          _c("td", [_vm._v("http://www.spo.go.kr")]),
          _c("td", [_vm._v("국번없이 1301")])
        ]),
        _c("tr", [
          _c("td", [_vm._v("경찰청 사이버안전국")]),
          _c("td", [_vm._v("http://cyberbureau.police.go.kr")]),
          _c("td", [_vm._v("국번없이 182")])
        ])
      ]),
      _c("b", [_vm._v("제11조 개인정보처리방침의 적용 범위")]),
      _vm._v(
        " 리뷰마인드의 개인정보처리방침은 ReviewMind 웹사이트(reviewmind.com)에 한해 적용됩니다. 리뷰마인드에서 제공하는 다른 회사의 웹사이트 링크로 이동하였을 경우, 해당 웹사이트의 개인정보처리방침이 적용되니 새로 방문한 웹사이트의 정책을 반드시 검토하시기 바랍니다. "
      ),
      _c("b", [_vm._v("제12조 개인정보처리방침의 변경")]),
      _vm._v(
        " 리뷰마인드의 개인정보처리방침은 ReviewMind 웹사이트(reviewmind.com)에 공개하여 누구나 쉽게 열람할 수 있도록 하고 있습니다. 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보취급방침을 수정할 경우, 변경사항을 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다. "
      ),
      _c("br"),
      _c("b", [_vm._v("시행일자: 2021년 06월 01일")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }