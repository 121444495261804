var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legalContent" }, [
      _c("h3", [_vm._v("리뷰마인드 서비스 이용약관 - V1")]),
      _c("b", [_vm._v("제1조(목적)")]),
      _vm._v(
        ' 이 약관은 리뷰마인드에서 운영하는 리뷰마인드(reviewmind.com) 서비스 및 그와 관련된 기타 서비스(이하 "서비스")를 이용함에 있어, 서비스를 이용하는 회원 또는 이용자의 권리, 의무와 책임사항 및 기타 필요한 사항을 규정하는 것을 목적으로 합니다. '
      ),
      _c("b", [_vm._v("제2조 약관의 효력 및 변경")]),
      _vm._v(
        " 1. 본 약관의 내용은 리뷰마인드 웹사이트 화면에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 회원에게 그 효력이 발생합니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 리뷰마인드는 변경사항을 시행 일자 15일 전부터 회원에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 불가피하게 회원에게 불리한 내용으로 변경할 경우 또는 중대한 사항을 변경하는 경우에는 그 시행 일자 30일 전부터 계정에 등록된 이메일 주소로 이메일 발송 또는 회원이 등록한 휴대폰번호로 문자메시지를 발송하는 방법 등으로 개별적으로 알려드립니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 리뷰마인드가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 회원의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제3조 약관의 적용범위")]),
      _vm._v(
        " 리뷰마인드에서 제공하는 서비스에는 기본적으로 이 약관이 적용되지만, 개별 서비스마다 별도의 이용약관 및 정책이 존재할 경우, 해당 서비스의 이용약관 및 정책이 우선하여 적용됩니다. "
      ),
      _c("b", [_vm._v("제4조 용어의 정의")]),
      _vm._v(
        " 이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 그리고 여기에서 정의되지 않은 약관 상 용어의 의미는 일반적인 관행에 따릅니다."
      ),
      _c("br"),
      _vm._v(
        " 1. 서비스 : 리뷰마인드에서 운영하는 웹사이트(reviewmind.com)에서 회원이 이용할 수 있는 서비스 및 그와 관련된 모든 서비스를 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 회원 : 리뷰마인드와 서비스 이용계약을 체결하고 서비스를 이용하는 모든 이용자를 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 비회원 : 리뷰마인드와 서비스 이용계약을 체결하지 않고 서비스를 이용할 수 있는 이용자를 말하며, 리뷰마인드는 ‘회원’과 ‘비회원’에게 제공하는 서비스에 차별을 두어 제공할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 이메일 : 회원의 식별 및 서비스 이용을 위하여 회원이 선정한 본인 이메일 주소를 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 5. 비밀번호 : 회원의 본인확인을 위해서 회원이 정한 문자 또는 숫자의 조합을 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 6. 유료서비스(멤버십) : 리뷰마인드가 제공하는 서비스 중 회원이 리뷰마인드에 일정 금액을 결제하거나 회원이 리뷰마인드 또는 리뷰마인드가 지정한 제3자로부터 제공된 콘텐츠 사용을 통해 이용하는 서비스를 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 7. 이용료 : 서비스를 통해 제공되는 기본 기능 및 부가적 기능 이용에 대한 요금을 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 8. 결제 : 리뷰마인드가 제공하는 유료서비스(멤버십)을 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 리뷰마인드 또는 리뷰마인드가 지정한 제3자에게 지불하는 것을 의미합니다."
      ),
      _c("br"),
      _vm._v(
        " 9. 리뷰마인드는 ‘멤버십’ 등의 명칭으로 유료서비스를 이용 가능한 이용권을 온라인 또는 오프라인(세금계산서 발행을 통한 서면 계약)으로 발행할 수 있으며, 사용방법은 사이트 페이지 등에 별도로 표기합니다."
      ),
      _c("br"),
      _vm._v(
        " 10. 게시물 : 회원이 서비스를 이용하면서 게시한 문자, 문서, 그림, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든 자료를 의미합니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제5조 이용계약의 체결")]),
      _vm._v(
        " 1. 이용계약은 회원이 리뷰마인드에서 제공하는 회원가입 화면에서 서비스 이용약관에 동의한 후 회원가입 신청을 하고, 리뷰마인드가 이에 대해 승낙함으로써 체결됩니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 다만, 다음 각호의 경우 리뷰마인드는 회원가입 신청에 대한 승낙을 거부하거나 서비스 제공 중단, 이용계약을 해지하는 등 적절한 제한 조치를 할 수 있습니다."
      ),
      _c("br"),
      _c("p", [
        _vm._v(
          " 1. 가입신청자가 이 약관에 의하여 이전에 어떠한 이유로 회원자격을 상실한 적이 있는 경우"
        ),
        _c("br"),
        _vm._v(
          " 2. 다른 사람의 이메일 주소를 이용하여 회원가입을 신청하거나, 다른 사람의 계정 및 비밀번호를 도용하거나, 다른 사람의 명의를 사용하거나, 다른 사람에게 계정을 증여를 받은 경우"
        ),
        _c("br"),
        _vm._v(
          " 3. 허위의 정보를 기재하거나, 필수 입력 정보를 기재하지 않은 경우"
        ),
        _c("br"),
        _vm._v(" 4. 만 14세 미만인 경우"),
        _c("br"),
        _vm._v(
          " 5. 회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우"
        ),
        _c("br"),
        _vm._v(
          " 6. 리뷰마인드의 정책에 적합하지 않는 회원으로 판단되는 경우나 서비스 제공이 곤란한 경우"
        ),
        _c("br"),
        _vm._v(
          " 7. 회원의 이용 목적이나 서비스 이용 방법이 리뷰마인드의 재산권이나 영업권을 침해하거나 침해할 우려가 있는 경우"
        ),
        _c("br"),
        _vm._v(
          " 8. 비정상적인 방법을 통하여 회원 아이디를 대량으로 생성하는 행위를 할 경우"
        ),
        _c("br"),
        _vm._v(" 9. 제공 서비스 설비 용량에 현실적인 여유가 없는 경우"),
        _c("br"),
        _vm._v(
          " 10. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우"
        ),
        _c("br"),
        _vm._v(
          " 11. 기타 리뷰마인드가 재정적, 기술적으로 필요하다고 인정하는 경우"
        ),
        _c("br"),
        _vm._v(
          " 12. 리뷰마인드로부터 회원자격정지 조치 등을 받은 회원이 그 조치 기간에 이용계약을 임의로 해지하고 재이용을 신청하는 경우"
        ),
        _c("br"),
        _vm._v(" 13. 기타 부정한 용도로 서비스를 사용하고자 하는 경우"),
        _c("br")
      ]),
      _vm._v(
        " 3. 이용자의 귀책사유로 인하여 리뷰마인드가 이용신청에 대해 승낙을 유보, 거절하거나 사후에 이용계약을 해지한 경우 리뷰마인드는 그 사실을 이용자에게 알려야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 본 서비스 이용계약은 리뷰마인드가 회원가입 신청절차 상에서 가입 완료를 표시한 시점에 성립합니다."
      ),
      _c("br"),
      _vm._v(
        ' 5. 제1항에 따른 신청에 있어 리뷰마인드는 "회원"의 종류에 따라 전문기관을 통하여 실명확인, 본인인증, 사업자등록번호 조회 등을 시행 할 수 있습니다.'
      ),
      _c("br"),
      _vm._v(
        " 6. 회원은 리뷰마인드에 언제든지 탈퇴를 요청할 수 있으며, 리뷰마인드는 즉시 회원 탈퇴 요청을 처리합니다."
      ),
      _c("br"),
      _vm._v(
        " 7. 회원은 웹사이트의 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 8. 회원은 회원가입 시 기재한 사항이 변경되었을 경우 웹사이트의 개인정보관리화면에서 수정하거나 고객센터 등을 통해 리뷰마인드에 변경사항을 알려야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 9. 제8항의 변경사항을 리뷰마인드에 알리지 않아 발생한 불이익에 대하여 리뷰마인드는 책임지지 않습니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제6조 개인정보보호")]),
      _vm._v(
        ' 리뷰마인드는 회원의 개인정보를 안전하게 보호하기 위하여 "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”)" 및/또는 “개인정보보호법”을 비롯한 모든 개인정보보호 관련 법률규정을 준수하기 위하여 개인정보처리방침을 제정하여 운영하고 있습니다. 자세한 사항은 "개인정보처리방침"에서 확인하실 수 있습니다. '
      ),
      _c("b", [_vm._v("제7조 회원의 계정 및 개인정보 관리 의무")]),
      _vm._v(
        " 1. 회원은 자신의 아이디와 비밀번호를 스스로 관리하여야 하며, 이를 소홀히 관리하여 발생하는 모든 손해 및 민형사상의 책임은 회원 자신에게 있습니다. 자신의 아이디 및 비밀번호를 다른 사람에게 제공하여서는 안되며, 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 리뷰마인드를 통해 알리고 그에 대한 조치에 따라야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 또한, 회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우, 리뷰마인드를 통해 알리고 변경요청을 해야 합니다. 개인정보의 수정을 요청하지 않아 발생하는 회원의 손해에 대해 리뷰마인드는 책임을 지지 않습니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 회원이 관련 법령, 리뷰마인드의 모든 약관 또는 정책을 준수하지 않는다면, 리뷰마인드는 회원의 위반행위 등을 조사할 수 있고, 회원의 서비스 이용을 잠시 또는 계속하여 중단하거나, 재가입에 제한을 둘 수도 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 부정 사용이 재차 발견될 경우 리뷰마인드는 위반 내용 고지와 함께 필요시 법적인 대응을 할 수 있습니다. 리뷰마인드는 회원에게 30일 간의 소명 기간을 부여하고, 소명 기간 동안 정당한 사유를 제시하지 못할 경우, 회원 등록을 말소하고 구입한 컨텐츠 및 권한을 종료할 수 있습니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제8조 리뷰마인드의 의무")]),
      _vm._v(
        " 리뷰마인드는 회원이 안전하게 서비스를 이용할 수 있도록 적절한 보안시스템을 구비하고 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수할 의무가 있습니다. "
      ),
      _c("b", [_vm._v("제 9조 멤버십 이용료")]),
      _vm._v(
        " 1. 회원은 리뷰마인드가 제공하는 유료서비스(멤버십)에 대하여 홈페이지 및 [리뷰마인드 멤버십 이용료]에 따른 이용료를 지불해야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 회원은 유료서비스(멤버십) 이용료를 제10조에 따라 매월 지정된 일자 또는 결제일에 지불해야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 회원은 최초 회원가입일이 아닌 유료서비스(멤버십)의 결제일을 기준으로 이용료를 지불해야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 예정된 결제 날짜에 회원의 귀책사유로 결제가 완료되지 않았다면 회원은 미납된 금액을 즉시 납부해야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 5. 예정된 결제일이 월의 마지막 일자인 경우 해당 일자가 없는 월에는 다른 일자에 결제될 수 있습니다."
      ),
      _c("br"),
      _c("b", [_vm._v("제 10조 이용료 지불 및 결제방법")]),
      _vm._v(
        " 1. 이용료의 지불은 카드(개인신용/체크), 법인카드, 기업용 세금계산서 발행을 통한 결제를 원칙으로 합니다. 이를 위하여 회원은 리뷰마인드가 제공하는 솔루션에 결제 가능한 카드를 등록, 혹은 사업자등록번호를 전달해야 합니다."
      ),
      _c("br"),
      _vm._v(" 2. 이용료의 지불은 다음 각 호의 방식으로 구분하여 진행됩니다."),
      _c("br"),
      _vm._v(
        " A. 정기 결제(카드) : 매월 지정된 일자에 최소 한 달 이상(이하 서비스 청구 기간, 단위 - 월)에 해당하는 기본 서비스 이용료를 회원이 지정한 카드로 선 결제 하는 방식"
      ),
      _c("br"),
      _c("p", [
        _vm._v(
          " A. 회원이 멤버십을 변경하거나 계약을 해지하는 경우에도 귀하가 최근 선택한 서비스 청구 기간 동안의 비용이 청구됩니다. 다만, 당사가 정당한 근거 없이 계약을 종료하거나, 개별 서비스를 종료하는 경우, 당사는 귀하가 결제한 서비스 이용료를 일할 계산하여 남은 기간 동안의 서비스 이용료 금액을 환불해 드립니다. "
        ),
        _c("br"),
        _vm._v(
          " B. 회원이 결제 수단을 선택 및 동의하면 이때 리뷰마인드는 결제를 위한 별도 페이지를 제공하고 이를 알립니다."
        ),
        _c("br"),
        _vm._v(
          " C. 회원이 지정한 결제 카드가 분실, 훼손, 변경, 거래정지, 기타 등의 사유로 유효하지 않은 경우 리뷰마인드는 결제 카드를 변경할 것을 안내하면서 미납된 금액을 청구할 수 있습니다."
        ),
        _c("br")
      ]),
      _vm._v(
        " B. 비 정기 결제/연간 결제(세금계산서) : 지정된 서비스 기간 외 기간(이하 서비스 청구 기간)의 기본 서비스 이용료를 일시에 선 결제하는 방식 "
      ),
      _c("b", [_vm._v("제 11조 환불")]),
      _vm._v(
        " 1. 회원은 리뷰마인드에 서비스 환불을 요구할 수 있습니다. 회원 별 환불 정책은 다음과 같습니다."
      ),
      _c("br"),
      _vm._v(" 2. 환불 금액 산정"),
      _c("br"),
      _c("p", [
        _vm._v(
          " A. 정기 결제(카드) 회원: 회원이 해지를 요청하면 가장 최근 회원이 해지를 요청한 일자를 기준으로 해당 일이 속하는 마지막 해당 월의 이용료를 제외한 나머지 기간(월)에 대한 금액 공제 후 남은 금액은 환불됩니다. 단, 해지 요청 후 서비스를 이용한 이력이 확인될 경우 마지막으로 서비스를 사용한 일자를 기준으로 하며, 서비스 청구 기간 중 회원이 해지를 요청한 해당 월 이전까지 서비스를 이용하지 않은 기간에 대한 환불은 제공하지 않습니다."
        ),
        _c("br"),
        _vm._v(" B. 카드 결제 환불은 카드 취소가 원칙입니다."),
        _c("br"),
        _vm._v(
          " C. 환불 금액 계산 시 무료 이용 기간은 적용되지 않으며, 환불은 신청 후 7일 이내 카드 취소처리 됩니다. 취소 신청 일자가 금요일 18시 이후인 경우 공휴일, 일요일을 제외하고 익일 처리 될 수 있습니다."
        ),
        _c("br")
      ]),
      _vm._v(
        " 3. 비 정기/연간 결제 (세금계산서) 회원 : 세금계산서를 통한 계약은 별도의 계약 절차에 따르며 해당 서류에 명시된 계약 조건에 따라야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 회원은 리뷰마인드 “마이페이지”에서 멤버십 별 각 서비스 이용 기간을 확인 할 수 있습니다. 멤버십 해지는 해당 페이지에서 신청할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 5. 환불 금액 산정 시 기준 일자는 회원이 해지를 신청한 일로부터 기산합니다."
      ),
      _c("br"),
      _vm._v(
        " 6. 회원이 해지를 이메일 또는 1:1문의를 통해 신청한 경우 문의가 도착한 일자를 기준으로 합니다."
      ),
      _c("br"),
      _vm._v(
        " 7. 회원이 해지를 요청한 후 안내 받은 공제 금 및 환불 가능 금액에 대해 동의하면 리뷰마인드는 동의 여부 확인 즉시 환불을 진행합니다. 단, 회원이 환불 가능 금액에 동의하지 않을 경우 환불을 진행할 수 없으며, 서비스 해지 요청은 취소 됩니다."
      ),
      _c("br"),
      _vm._v(
        " 8. 환불 가능 금액에 동의하지 않아 회원이 해지를 요청한 시점이 경과하여, 서비스 이용의 일 수가 증가하여 환불 가능 금액이 변경 될 경우, 리뷰마인드는 회원의 로그인 이력, 로그 기록을 기준으로 해당 회원이 기산일 이후 추가로 서비스를 이용했는지 여부를 판단하여 환불 가능 금액을 다시 안내 할 수 있습니다. "
      ),
      _c("b", [_vm._v("제 12 조 리뷰마인드 멤버십")]),
      _vm._v(
        " 1. 리뷰마인드 멤버십은 회원이 이용기간 동안 정해진 수량만큼의 분석 제품을 등록하여 이용할 수 있는 서비스입니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드는 멤버십 회원의 마이페이지에 등록할 수 있는 분석 제품의 수와 변경 횟수를 지정할 수 있고, 이를 차별화한 다양한 멤버십 상품으로 구성할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 회원이 멤버십 이용기간 동안 지정된 개수만큼 제품을 등록하지 않거나 서비스를 이용하지 않은 경우라도 잔여 수량 또는 서비스 미 이용분에 대해서는 환불 또는 이월되지 않습니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 회원이 등록한 제품의 리뷰 데이터의 공급계약이 종료되거나 변경되어 회원이 해당 제품의 분석 서비스를 이용하지 못하는 경우가 발생 가능합니다. 이러한 변경 사항이 발생하는 경우 리뷰마인드는 즉시 해당 리뷰 데이터의 변경된 내용에 관한 정보를 회원에게 제공합니다."
      ),
      _c("br"),
      _vm._v(
        " 5. 회원이 멤버십 이용료를 결제하지 않거나, 취소하는 등의 이유로 서비스 이용이 해지되는 경우, 멤버십 회원에게만 제공되는 콘텐츠를 회원의 마이페이지에 더 이상 노출하지 않고, 회원은 마이페이지에 등록된 제품들의 분석 정보를 더 이상 사용할 수 없습니다."
      ),
      _c("br"),
      _vm._v(
        " 6. 멤버십 이용이 해지되는 경우, 회원 마이페이지에 등록된 제품 분석 정보는 해지 시점부터 6개월간 보관한 뒤 영구 삭제하며 영구 삭제 후에는 복구가 불가능합니다. "
      ),
      _c("b", [_vm._v("제 13 조 과오금 ")]),
      _vm._v(
        " 1. 리뷰마인드는 멤버십 이용료에 관하여 과오금이 발생한 경우 회원이 이용료를 결제한 방법과 동일하게 과오금을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드의 책임 있는 사유로 과오금이 발생한 경우 리뷰마인드는 계약 비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, 회원의 책임 있는 사유로 과오금이 발생한 경우, 리뷰마인드가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 리뷰마인드는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용료가 부과되었음을 입증할 책임을 집니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 리뷰마인드는 과오금의 환불 절차를 『콘텐츠 이용자 보호지침』에 따라 처리합니다. "
      ),
      _c("b", [_vm._v("제 14조 서비스 이용 관련 주의사항")]),
      _vm._v(
        " 리뷰마인드는 모든 회원이 리뷰마인드에서 제공하는 서비스를 안전하게 이용할 수 있도록 아래의 경우, 서비스 제공 중단, 이용계약 해지, 재가입 제한 등 적절한 조치를 할 수 있습니다. 또한 불법적인 행위라고 판단될 경우, 위반 행위의 경중에 따라 수사 기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 1. 회원가입 시 또는 회원정보 변경 시 허위내용을 기재하거나 타인의 정보를 도용하는 경우"
      ),
      _c("br"),
      _vm._v(
        " 2. 타인의 계정 및 정보를 무단으로 수집, 이용하거나 다른 사람들에게 제공하는 경우"
      ),
      _c("br"),
      _vm._v(
        " 3. 리뷰마인드 또는 리뷰마인드의 운영자, 임직원을 사칭하거나 관련 정보를 도용하는 경우"
      ),
      _c("br"),
      _vm._v(
        " 4. 타인 또는 타사에 대한 의미 없는 욕설, 모욕, 희롱, 위협, 명예훼손 등이 포함된 게시물을 작성하여 블라인드 등의 경고 조치 후에도 해당 행위를 지속하는 경우"
      ),
      _c("br"),
      _vm._v(" 5. 리뷰마인드 가 게시한 정보를 임의로 변경하는 경우"),
      _c("br"),
      _vm._v(
        " 6. 리뷰마인드 및 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권 및 영업권, 비즈니스 모델, 재산권을 침해하는 경우"
      ),
      _c("br"),
      _vm._v(
        " 7. 음란정보, 폭력적인 메시지, 기타 공서양속에 위반되는 정보를 공개 또는 게시하는 경우"
      ),
      _c("br"),
      _vm._v(
        " 8. 서비스의 이용권한 및 기타 이용계약상의 지위를 제3자에게 양도, 증여, 대여하거나, 담보로 제공하는 경우"
      ),
      _c("br"),
      _vm._v(" 9. 서비스의 컨텐츠를 재판매 하는 경우"),
      _c("br"),
      _vm._v(
        " 10. 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등 관련법을 위반한 경우"
      ),
      _c("br"),
      _vm._v(
        " 11. 기타 불법적인 행위가 있거나 서비스의 정상적인 운영을 방해한 경우 "
      ),
      _c("b", [_vm._v("제15조 서비스의 중단 및 변경")]),
      _vm._v(
        " 1. 리뷰마인드는 컴퓨터 또는 서버 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 천재지변이나 국가비상사태, 해결이 곤란한 기술적 결함, 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 리뷰마인드는 공지사항 게시판 등을 활용하여 미리 통지합니다. 만약 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 최대한 빠른 시일 내에 서비스를 복구한 후 통지하도록 하겠습니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드가 제공하는 서비스의 형태나 내용은 수시로 변경되거나 중단될 수 있습니다. 이러한 변경사항에 대해서는 회원에게 사전 통지하지 않지만, 회원에게 불리한 것으로 판단되는 사항에 대해서는 이메일 또는 기타 방법으로 개별 통지하도록 하겠습니다. "
      ),
      _c("b", [_vm._v("제16조 정보의 제공 및 광고의 게재")]),
      _vm._v(
        " 1. 리뷰마인드 서비스 이용에 필요하다고 인정되는 다양한 정보를 회원 가입 시 기재한 회원의 개인정보 및 회원이 변경한 개인정보를 활용하여 이메일 또는 문자메시지, 전화 등의 방법으로 회원에게 제공할 수 있으며, 영리목적의 광고성 정보를 전송하는 경우에는 회원의 사전 동의를 얻습니다. 회원이 위 정보의 수신을 원하지 않는 경우에는 언제든지 이를 거부할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 회원이 전항에 의하여 정보 수신의 모든 방법에 관하여 수신 거부를 하는 경우에도 리뷰마인드는 서비스 이용에 필수적으로 요구되는 정보의 확인 및 리뷰마인드의 판단에 따라 회원이 반드시 알아야 하는 중대한 정보를 회원의 이메일 알림 등으로 제공할 수 있으며, 회원은 이러한 정보 수신에 관하여 동의합니다. "
      ),
      _c("b", [_vm._v("제17조 게시물 정책")]),
      _vm._v(
        " 1. 리뷰마인드에서 제공하는 서비스 내에 회원이 작성한 게시물에 대한 저작권 및 책임은 게시물을 등록한 회원에게 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드는 서비스를 운영, 개선, 홍보하기 위한 목적으로 회원이 작성한 게시물을 리뷰마인드가 운영하는 서비스 또는 다른 회사가 운영하는 서비스에 노출, 수정, 복제, 전송, 전시, 보도, 방영할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 회원이 회원탈퇴를 한 경우, 리뷰마인드에 회원이 작성하였던 게시물은 삭제되지 않습니다. 회원 탈퇴와 동시에 회원의 개인정보가 삭제되기 때문에 작성자 본인을 확인할 수 없어 게시물 편집 및 삭제가 원천적으로 불가하니, 회원이 작성한 게시물의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 모두 삭제하여야 합니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 리뷰마인드는 정책에 따라 부적절한 글로 판단된 게시물은 블라인드 처리하고 있으나, 리뷰마인드가 임의로 회원의 게시물을 삭제하거나 수정하지 않는 것을 원칙으로 하고 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 5. 회원이 작성한 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 리뷰마인드는 해당 게시물의 게시를 중단하거나 및 삭제하는 등 적절한 조치를 취할 수 있으며, 그로 인해 발생하는 민∙형사상의 모든 책임은 회원이 부담하여야 합니다. "
      ),
      _c("b", [_vm._v("제18조 리뷰마인드 서비스의 저작권")]),
      _vm._v(
        " 1. 리뷰마인드가 작성한 저작물에 대한 저작권, 특허권, 상표권 기타 지식재산권은 리뷰마인드에 귀속됩니다. 회원은 리뷰마인드에서 운영하는 서비스를 이용함으로써 얻은 정보를 리뷰마인드의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하여서는 안 됩니다."
      ),
      _c("br"),
      _vm._v(
        " 2. 리뷰마인드는 회원과 별도로 서면 계약을 체결하여 리뷰마인드가 제공하는 서비스 및 제반 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를 부여하지 아니하는 한, 회원에게 리뷰마인드 또는 리뷰마인드가 제공하는 서비스의 상호, 상표, 서비스 표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드 특성을 이용할 수 있는 권리를 부여하지 않습니다."
      ),
      _c("br"),
      _vm._v(
        " 3. 리뷰마인드와 체결한 별도의 계약에 따라 특별히 허용된 경우 및 리뷰마인드 웹사이트의 robots.txt 파일 규정에서 허용된 경우를 제외하고, 리뷰마인드가 서비스하는 콘텐츠(제품정보, 랭킹 등)를 무단으로 크롤링(crawling), 스크래핑(scraping), 캐싱(caching) 또는 액세스하거나 다른 웹사이트 또는 다른 미디어에 미러링하는 행위 또는 그러한 모든 시도는 금지됩니다. 리뷰마인드는 단독 판단에 따라 크롤링, 스크래핑, 캐싱 등 본 항에 명시된 금지행위와 연관된 접속을 차단할 수 있습니다."
      ),
      _c("br"),
      _vm._v(
        " 4. 회원은 리뷰마인드가 서비스하는 컨텐츠를 재판매 하여서는 안 됩니다. "
      ),
      _c("b", [_vm._v("제19조 이용계약 해지")]),
      _vm._v(
        " 회원은 리뷰마인드의 서비스 이용을 중단하고 싶을 경우, 언제든지 이용계약을 해지할 수 있습니다. 단, 이용계약이 해지되는 경우에도 회원이 작성한 게시물은 삭제되지 않습니다. 게시물의 삭제를 원하실 경우, 반드시 해지신청 전에 회원의 게시물을 삭제하여야 합니다. "
      ),
      _c("b", [_vm._v("제20조 손해배상")]),
      _vm._v(
        " 1. 리뷰마인드는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 리뷰마인드는 CP(Contents Provider)가 제공하거나 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 리뷰마인드의 과실 없이 발생된 회원의 손해에 대하여는 책임을 부담하지 아니합니다. 2. 리뷰마인드는 리뷰마인드의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만 리뷰마인드는 리뷰마인드의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 리뷰마인드는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다. "
      ),
      _c("p", [
        _vm._v(" - 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해"),
        _c("br"),
        _vm._v(" - 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우"),
        _c("br"),
        _vm._v(" - 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해"),
        _c("br"),
        _vm._v(
          " - 제3자가 불법적으로 리뷰마인드의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해"
        ),
        _c("br"),
        _vm._v(
          " - 제3자가 리뷰마인드 서버에 대한 전송 또는 리뷰마인드 서버로부터의 전송을 방해함으로써 발생하는 손해"
        ),
        _c("br"),
        _vm._v(
          " - 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해"
        ),
        _c("br"),
        _vm._v(
          " - 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해"
        ),
        _c("br"),
        _vm._v(
          " - 기타 리뷰마인드의 고의 또는 과실이 없는 사유로 인해 발생한 손해 "
        )
      ]),
      _c("b", [_vm._v("제21조 준거법 및 분쟁의 해결")]),
      _vm._v(
        " 본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 리뷰마인드와 회원 간에 발생한 분쟁의 해결은 민사소송법에서 정한 관할법원에서의 소송에 의할 수 있습니다. "
      ),
      _c("b", [_vm._v("[부칙]")]),
      _vm._v(
        " 이 약관은 2021년 06월 01일자부터 시행하며, 종전의 약관을 대체합니다. "
      ),
      _c("b", [_vm._v("적용일자 : 2021년 06월 01일")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }